import Axios from "axios";
import { GetToken } from "../helper";

import { Modal } from "antd";
import { API_ADDRESS } from "..";

import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/tr";
dayjs.locale("tr");

export interface IOrder {
  description: string;
  date: string;
  customer: string;
  phone_number: string;
  address: string;
  city: string;
  country: string;
  production_unit: string;
  order_no: string;
  order_tooker: string;
  order_amount: number;
  status: string;
  type: string;
  serial_number: string;
  cooler_type: string;
  tank_size: number;
  pump: string;
  pump_serial: string;
  year: string;
  tow_type: string;
  meme_type: string;
  meme_distance: string;
  arm_type: string;
  arm_length: string;
  chassis_color: string;
  caldron_type: string;
  caldron_color: string;
  commander: string;
  alt_features: string;
  brand: string;
}

export interface ITurboOrder extends IOrder {
  caldron_type: string;
  fan_radius: string;
  fan_type: string;
}

export interface ISleevedOrder extends IOrder {
  arm_length: string;
  arm_type: string;
  meme_distance: string;
  meme_type: string;
  mixer_type: string;
}

export interface GenericOrder extends ITurboOrder, ISleevedOrder {
  id: number;
  machine_id: number;
  last_location_date: string;
  planned_date?: string;
  laststatus?: string;
}

export class GetTaskRequestConfig {
  turbo: boolean = false;
  kollu: boolean = false;
  diger: boolean = false;
  test: boolean = false;
  bahce: boolean = false;
  depo: boolean = false;
  iptal: boolean = false;
  teslimat: boolean = false;
  onaybekliyor: boolean = false;
}

export async function CreateUsedMachine(
  order: ITurboOrder | ISleevedOrder,
  serialCode: string
) {
  order.date = dayjs().format("YYYY-MM-DD HH:mm");
  if (order.tank_size) order.tank_size = parseInt(String(order.tank_size));
  if (order.order_amount)
    order.order_amount = parseInt(String(order.order_amount));
  order.status = order.production_unit;
  order.serial_number = serialCode;

  const response = await Axios.post(
    API_ADDRESS + "/api/order/CreateUsedMachine/",
    order,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla oluşturuldu.</p>,
          okText: "Tamam",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function UpdateUsedMachine(
  order: ITurboOrder | ISleevedOrder,
  serialCode: string
) {
  order.date = dayjs().format("YYYY-MM-DD HH:mm");
  if (order.tank_size) order.tank_size = parseInt(String(order.tank_size));
  if (order.order_amount)
    order.order_amount = parseInt(String(order.order_amount));
  order.status = order.production_unit;
  order.serial_number = serialCode;

  const response = await Axios.put(
    API_ADDRESS + "/api/order/UpdateUsedMachine/",
    order,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla oluşturuldu.</p>,
          okText: "Tamam",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function TakeOrder(
  order: ITurboOrder | ISleevedOrder,
  serialCode: string
) {
  order.date = dayjs().format("YYYY-MM-DD HH:mm");
  order.year = dayjs().format("YY");
  if (order.tank_size) order.tank_size = parseInt(String(order.tank_size));
  if (order.order_amount)
    order.order_amount = parseInt(String(order.order_amount));
  order.status = order.production_unit;
  order.serial_number = serialCode;

  const response = await Axios.post(
    API_ADDRESS + "/api/order/TakeOrder/",
    order,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla oluşturuldu.</p>,
          okText: "Tamam",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function TakeOrderDealer(
  order: ITurboOrder | ISleevedOrder,
  serialCode: string
) {
  order.date = dayjs().format("YYYY-MM-DD HH:mm");
  order.year = dayjs().format("YY");
  if (order.tank_size) order.tank_size = parseInt(String(order.tank_size));
  if (order.order_amount)
    order.order_amount = parseInt(String(order.order_amount));
  order.status = order.production_unit;
  order.serial_number = serialCode;
  order.order_tooker = "";

  const response = await Axios.post(
    API_ADDRESS + "/api/order/TakeOrderDealer/",
    order,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla oluşturuldu.</p>,
          okText: "Tamam",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function UpdateOrder(
  order: ITurboOrder | ISleevedOrder,
  serialCode: string
) {
  order.date = dayjs().format("YYYY-MM-DD HH:mm");
  order.year = dayjs().format("YY");
  if (order.tank_size) order.tank_size = parseInt(String(order.tank_size));
  if (order.order_amount)
    order.order_amount = parseInt(String(order.order_amount));
  order.status = order.production_unit;
  order.serial_number = serialCode;

  const response = await Axios.post(
    API_ADDRESS + "/api/order/EditOrder/",
    order,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla güncellendi.</p>,
          okText: "Tamam",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function GetFinishedOrders() {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetFinishedOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}
export async function GetFinishedOrdersForDealers() {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetFinishedDealerOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}
export async function GetMachineStatus() {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetMachinePartsStatuses",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}

export async function GetCancelledOrders() {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetCancelledOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}

export async function GetAllOrders() {
  const response = await Axios.get(API_ADDRESS + "/api/order/GetAllOrders", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}
export async function GetAllDealerOrders() {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetAllDealerOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen bilgileri kontrol edin.</p>,
        okText: "Tamam",
      });
      return [];
    });

  return response;
}

export async function GetAllOrdersByType(type: string) {
  const response = await Axios.get(
    API_ADDRESS + `/api/order/GetAllOrdersByType?type=${type.toLowerCase()}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}
export async function GetAllTankFromSaruhanli() {
  const response = await Axios.get(
    API_ADDRESS + `/api/order/GetAllTankFromSaruhanlı`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}

export async function GetPendingOrders() {
  const response = await Axios.get(API_ADDRESS + "/api/task/GetPendingOrders", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetProductionReadyOrders() {
  const response = await Axios.get(
    API_ADDRESS + "/api/task/GetProductionReadyOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetApprovalPendingOrders() {
  const response = await Axios.get(
    API_ADDRESS + "/api/ProductionTasks/GetApprovalPendingOrders",
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function addApproval(orderId: number) {
  const response = await Axios.post(
    `${API_ADDRESS}/api/ProductionTasks/AddApproval?order_id=${orderId}`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}
export async function dealerConfirmOffer(orderId: number) {
  const response = await Axios.post(
    `${API_ADDRESS}/api/ProductionTasks/DealerConfirmOffer?order_id=${orderId}`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}
export async function dealerDenyOffer(orderId: number) {
  const response = await Axios.post(
    `${API_ADDRESS}/api/ProductionTasks/DealerDenyOffer?order_id=${orderId}`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}
export async function updateAnOffer(
  order_id: string,
  price: number,
  payment_detail: string
) {
  const numberPrice = Number(price);
  const response = await Axios.put(
    `${API_ADDRESS}/api/order/SetPaymentDetails`,
    { order_id, price: numberPrice, payment_detail },
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function GetOrderDetails(serial_number: string) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/getOrderById/${serial_number}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function CopyOrder(serial_number: string) {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/CopyOrder/" + serial_number,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        Modal.success({
          title: "İşlem Başarili.",
          content:
            "Sipariş başarıyla kopyalandı. Lütfen siparişler sayfasını kontrol edin",
        });
        return true;
      }
    })
    .catch(() => {
      Modal.error({
        title: "Bir hata oluştu",
        content: <p>Lütfen tekrar kontrol edin..</p>,
        okText: "Tamam",
      });
      return false;
    });

  return response;
}

export async function GetMachineListFromOrderById(orderId: number) {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetOrderMachineDetailsById/" + orderId,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}

export async function GetOrderHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/getOrderHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
export async function GetFinancialApproveHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/GetFinancialApproveHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetArmHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/getArmHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetFanHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/getFanHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetChassisHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/getChassisHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetGenericTaskHistory(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/task/GetGenericTaskHistory/${machine_id}`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetDeliveryReport() {
  const response = await Axios.get(API_ADDRESS + "/api/order/GetDeliveries", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}

export async function GetOrderReport() {
  const response = await Axios.get(API_ADDRESS + "/api/order/GetOrders", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}

export async function GetMovableMachines() {
  let requestConfig = new GetTaskRequestConfig();
  requestConfig.bahce = true;
  requestConfig.depo = true;

  const response = await Axios.put(
    API_ADDRESS + "/api/task/GetAllMachinesStatus",
    requestConfig,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

interface ISwitchMachines {
  machine1: { serial_number: string; order_id: string };
  machine2: { serial_number: string; order_id: string };
  keepMachineDetails: boolean;
}

export async function SwitchMachines(machines: ISwitchMachines) {
  const response = await Axios.post(
    API_ADDRESS + "/api/order/SwitchMachines",
    machines,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla kaydırıldı.Lütfen sayfayı yenileyin</p>,
          okText: "Tamam",
        });
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
    });

  return response;
}

interface ISetChassisNo {
  machine_id: { id: number };
  chassis_no: { chassisNo: string };
}

export async function SetChassisNo(chassisNo: ISetChassisNo) {
  const response = await Axios.put(
    API_ADDRESS + "/api/Order/SetChassisNo",
    chassisNo,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla kaydırıldı.Lütfen sayfayı yenileyin</p>,
          okText: "Tamam",
        });
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
    });

  return response;
}

interface ISetSasNo {
  machine_id: { id: number };
  sas_no: { sas_no: string };
}

export async function SetSasNo(sasNo: ISetSasNo) {
  const response = await Axios.put(API_ADDRESS + "/api/Order/SetSasNo", sasNo, {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla kaydırıldı.Lütfen sayfayı yenileyin</p>,
          okText: "Tamam",
        });
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
    });

  return response;
}

export async function GetAllTankProducerOrders() {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/GetAllTankProducerOrders`,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return [];
    })
    .catch((err) => {
      return [];
    });

  return response;
}

export async function sendToTankProducer(place: String, id: String) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/order/SendToTankProducerOrders/`,
    { CurrentPosition: place, Id: id },
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
        return res.data;
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function sendTo(place: String, id: number, testComment?: String) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/order/MoveTo/${place}/${id}/${testComment}`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function UndoFanTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/UndoFanTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function UndoArmTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/UndoArmTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function UndoTankProductorTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/UndoTankProductorTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });
  return response;
}

export async function RedoTankProductorTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/RedoTankProductorTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });
  return response;
}

export async function UndoChassisTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/UndoChassisTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function UndoSendTo(machine_id: number) {
  const response = await Axios.get(
    `${API_ADDRESS}/api/order/UndoMoveTo/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>İşlem başarıyla gerçekleşti..</p>,
          okText: "Tamam",
        });
      }
      return true;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function GetActiveOrders() {
  let requestConfig = new GetTaskRequestConfig();
  requestConfig.turbo = true;
  requestConfig.kollu = true;
  requestConfig.diger = true;
  requestConfig.test = true;
  requestConfig.depo = true;
  requestConfig.bahce = true;

  const response = await Axios.put(
    API_ADDRESS + "/api/task/GetAllMachinesStatus",
    requestConfig,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetAllUsedMachines(status: string) {
  const response = await Axios.get(
    API_ADDRESS + "/api/order/GetAllUsedMachines/" + status,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function DeleteUsedMachine(machine_id: number) {
  const response = await Axios.delete(
    API_ADDRESS + "/api/order/DeleteUsedMachine/" + machine_id,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function SellUsedMachine(body: any) {
  const response = await Axios.put(
    API_ADDRESS + "/api/order/SellUsedMachine",
    body,
    {
      headers: {
        Authorization: "Bearer " + GetToken(),
      },
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function DeleteOrder(order_id: String, reason: String) {
  const response = await Axios.post(
    `${API_ADDRESS}/api/order/DeleteOrder/`,
    { Id: order_id, reason },
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Sipariş başarıyla iptal edildi.</p>,
          okText: "Tamam",
        });
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function DeleteSingleMachine(machine_id: string) {
  debugger;
  const response = await Axios.delete(
    `${API_ADDRESS}/api/order/DeleteSingleMachine/${machine_id}`,
    { headers: { Authorization: "Bearer: " + GetToken() } }
  )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Makine başarıyla silindi.</p>,
          okText: "Tamam",
        });
      }
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return null;
    });

  return response;
}

export async function GetShortcuts() {
  const response = await Axios.get(API_ADDRESS + "/api/Task/GetLayout", {
    headers: {
      Authorization: "Bearer " + GetToken(),
    },
  }).then((res) => {
    return res.data;
  });

  return response;
}

export async function AddShortcut(items: any, selectedData: string) {
  const response = await axios
    .post(
      API_ADDRESS + `/api/Task/SaveLayoutInfo/`,
      {
        layout: items?.length
          ? [...items, selectedData].join(",")
          : selectedData,
      },
      {
        headers: {
          Authorization: "Bearer " + GetToken(),
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Kısayol başarıyla eklendi.</p>,
          okText: "Tamam",
        });
      }
      return res.data.layout;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return "";
    });

  return response;
}
export async function RemoveShortcuts(items: string[], removedItem: string) {
  const response = await axios
    .post(
      API_ADDRESS + `/api/Task/SaveLayoutInfo/`,
      { layout: items.filter((data) => data !== removedItem).join(",") },
      {
        headers: {
          Authorization: "Bearer " + GetToken(),
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        Modal.success({
          title: "Başarılı",
          content: <p>Kısayol başarıyla kaldırıldı.</p>,
          okText: "Tamam",
        });
      }
      return res.data.layout;
    })
    .catch((err) => {
      Modal.error({
        title: "Hata",
        content: <p>Bir hata oluştu, lütfen tekrar deneyin.</p>,
        okText: "Tamam",
      });
      return "";
    });

  return response;
}

export async function sendToFan(
  place: String,
  id: number,
  getHistory?: () => void
) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/productiontasks/MoveToFan/${place}/${id}/`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  ).then((res) => {
    if (res.status === 200 || res.status === 204) {
      if (getHistory) {
        getHistory();
      }
      return res.data;
    }
  });

  return response;
}
export async function sendToChassis(
  place: String,
  id: number,
  getHistory?: () => void
) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/productiontasks/MoveToChassis/${place}/${id}/`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  ).then((res) => {
    if (res.status === 200 || res.status === 204) {
      if (getHistory) {
        getHistory();
      }
    }
  });
  return response;
}

export async function sendToArm(
  place: String,
  id: number,
  getHistory?: () => void
) {
  const response = await Axios.put(
    `${API_ADDRESS}/api/productiontasks/MoveToArm/${place}/${id}/`,
    {},
    { headers: { Authorization: "Bearer: " + GetToken() } }
  ).then((res) => {
    if (res.status === 200 || res.status === 204) {
      if (getHistory) {
        getHistory();
      }
      return res.data;
    }
  });

  return response;
}
